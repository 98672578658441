@use '@nex/labs/src/styles/common.scss' as *;

.TutorialOnboarding {
  position: fixed;
  max-width: 260px;
  z-index: 9999;
  top: 0;
  background: var(--primary-white);
  border: 1px solid var(--border-light);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  padding: 18px;
  color: #6c727f;

  .TutorialHeader {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    color: #182230;
  }

  .TutorialFooter {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
  }

  .Button {
    height: 24px;
    width: 24px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary-gray);
    border-radius: 50%;

    &:disabled {
      opacity: 0.4;
    }

    img {
      width: 12px;
      height: 12px;
      opacity: 0.8;
    }
  }
}
