.Animator {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 32px;

  &Content {
    margin-top: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;

    &IdleText {
      color: #71717a;
      font-weight: 800;
      font-size: 18px;
    }
  }

  &Footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
