@use '@nex/labs/src/styles/common.scss' as *;

.FloatBar {
  &MainHeader {
    display: flex;
    justify-content: end;
    gap: 12px;
    padding: 0;
    background: transparent;
    position: fixed;
    right: 18px;
    top: 18px;
    z-index: 100;
    justify-content: space-between;
    width: calc(100% - var(--aside-width) - 40px);

    .FloatBarCredit,
    .FloatBarNavMenu {
      background: #ffffffe6;
      border-radius: 8px;
      box-shadow:
        0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      backdrop-filter: blur(21px) saturate(5.5);
    }

    .FloatBarUpgrade,
    .FloatBarCredit {
      font-size: var(--font-accent);
    }
  }

  @include media('lg', 'max') {
    &MainHeader {
      > span {
        display: none;
      }

      padding: 0 18px;
      justify-content: space-between;
      gap: 8px;
      width: 100%;
      position: fixed;
      left: 50%;
      right: unset;
      transform: translateX(-50%);
      top: 18px;
      z-index: 100;

      & > *:not(button, a) {
        box-shadow: none !important;
      }
    }
  }

  &NavMenu {
    display: flex;
    background: var(--primary-white);
    gap: 24px;
    padding: 8px 18px;
  }

  &Credit {
    display: flex;
    padding: 8px 16px;
    background: var(--primary-white);
    align-items: center;
    justify-content: center;
    font-weight: 800;
  }
}
