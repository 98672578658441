@use '@nex/labs/src/styles/common.scss' as *;

.SketchWrapper {
  position: fixed;
  bottom: 18px;
  left: var(--floating-controls);
  transform: translateX(-50%);
  z-index: 100;
  display: flex;
  gap: 18px;
  align-items: center;
}

.Controls {
  background: var(--primary-gray);
  padding: 4px;

  > * {
    @include media('xl', 'max') {
      white-space: pre;
    }
  }

  display: flex;
  align-items: center;
  gap: 8px;
  background: var(--primary-white);
  box-shadow: 0px -2px 8px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  @include media('lg', 'max') {
    width: 100%;
    max-width: 650px;
  }

  .Active {
    background: var(--primary-theme);
  }

  button {
    &:hover {
      background: #dadada;
    }
  }

  > span:not([role='button']) {
    background: #e9ecf2;
    width: 1px;
    height: 30px;
    display: block;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 8px;
    border-radius: 6px;
    font-weight: 600;
    font-size: var(--font-p);
    background: var(--primary-gray);

    svg {
      height: 20px;
      width: 20px;
    }
  }
}
