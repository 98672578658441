@use '@nex/labs/src/styles/common.scss' as *;

.GenerationImages {
  background: var(--primary-gray);
  border-right: 1px solid var(--border-light);
  margin-top: -50px;
  margin-left: -20px;
  height: 100%;
  margin-bottom: -24px;
  flex: 1 1 78%;
  max-width: 78%;
  min-width: 900px;

  @include media('xl', 'max') {
    flex: 1 1 100%;
    max-width: 100vw;
    min-width: auto;
    margin-left: -14px;
    margin-right: -14px;
    flex-direction: column-reverse !important;

    > div {
      max-width: 100%;
      > div {
        flex-direction: row !important;
      }
    }
  }

  .GenerationImage {
    padding: 32px;
    max-width: fit-content;
    margin: auto;
    position: relative;

    @include media('lg', 'max') {
      padding: 56px 32px 32px;
    }
  }
  // .VoteOverlay {
  //   position: absolute;
  //   right: 32px;
  //   margin-top: -32px;

  //   @include media('lg', 'max') {
  //     margin-top: -24px;
  //   }
  // }

  .GenerationImageActions {
    position: absolute;
    right: 40px;
    bottom: 6%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    @include media('xl', 'max') {
      bottom: calc(6% + 17px);
    }

    button {
      background: #ffffffba !important;
      border-radius: 50px;
      padding: 4px 8px;
      backdrop-filter: blur(14px);
    }
  }
}

.GenerationActionPreviewBar {
  background: white;
  padding: 12px;
  position: relative;
  display: flex;
  justify-content: center;
}

.ResultsActions {
  position: absolute;
  top: 50%;
  width: 97%;
  left: 20px;
  transform: translatey(-50%);

  @include media('lg', 'max') {
    top: 0%;
    width: 95%;
    left: 50%;
    margin-top: 32px;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
}
