@use '@nex/labs/src/styles/common.scss' as *;

.AuthLayout {
  border-radius: 12px;
  /* margin: 4rem auto 6rem; */
  max-width: 100%;
  margin-top: -82px;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  gap: 40px;
  overflow: hidden;
  background: #f9fafb;

  @include media('lg', 'max') {
    margin: 2rem auto;
    background: transparent;
    border: none;
    padding: 24px;
  }

  aside {
    position: relative;
    min-height: 680px;
    height: auto;
    padding: 48px 30px 30px;
    display: flex;
    flex-direction: column;
    background-size: 100% 100%;
    background-position: center;
    background-color: var(--deep-black);
    justify-content: space-between;
    gap: 8px;
    border-radius: 0px;

    max-width: 400px;
    flex: 0 0 400px;

    @include media('lg', 'max') {
      display: none;
    }

    .Logo {
      width: 78px;
      height: auto;
    }

    h4 {
      max-width: 400px;
    }

    .Testimonial {
      @include applySmartBg('black', 'regular', 'normal');
      border-radius: var(--border-radius-md);
      padding: 18px;
      display: flex;
      flex-direction: column;
      gap: 18px;
      max-width: 100%;

      img {
        height: 40px;
        width: 40px;
        object-fit: cover;
        border-radius: var(--border-radius-sm);
      }

      &:before {
        @include applySmartBg('black', 'regular', 'normal');
        width: 90%;
        height: 10px;
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 0px 0px 10px 10px;
        z-index: -1;
      }
    }
  }

  main {
    width: 100%;
    height: 100%;
    padding: 40px 0;
    margin: auto;
    display: flex;
    justify-content: center;
    max-width: fit-content;

    > .Logo {
      display: none;
      width: 65px;
      height: auto;
      margin-bottom: 40px;

      @include media('lg', 'max') {
        display: block;
      }
    }

    @include media('lg', 'max') {
      margin: unset;
      padding: 18px 0;
    }
  }

  &:before {
    // background: var(--deep-black);
    // filter: blur(100);
    content: '';
    position: fixed;
    width: 100%;
    top: 50%;

    z-index: -1;
    left: 50%;
    animation: fade 0.5s ease-in-out alternate;
    transform: translate(-50%, -50%);
    height: 100%;
    opacity: 0.3;
    @keyframes fade {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}
