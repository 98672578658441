@use '@nex/labs/src/styles/common.scss' as *;

.ActionBarWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  padding: 4px;
  flex-wrap: wrap;

  @include media('xl', 'max') {
    align-items: center;
    gap: 8px;
  }

  .ActionInput {
    flex: 0 1 30%;

    @include media('lg', 'max') {
      flex: 1 1 100%;
      width: 100%;
    }

    > fieldset {
      > div {
        background: var(--primary-white);

        textarea {
          max-height: 110px;
          min-height: 80px;

          @include media('lg', 'max') {
            min-height: 50px;
          }
        }
      }
    }
  }

  .ActionBlocksSettings {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    > span {
      width: 100%;
      flex: 1;
      display: block;
      max-width: 90px;
      height: 100%;

      @include media('xl', 'max') {
        height: 100%;

        > button {
          padding: 6px;
          height: 32px !important;
        }
      }

      > button {
        background-color: rgba(0, 0, 0, 0.3);
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-position: center;
        background-size: cover;
        justify-content: center;
        padding: 10px;
        height: 100%;
        border-radius: 8px;
        font-size: var(--font-p);
        font-weight: 700;
        gap: 4px;
        color: #fff;

        &.active {
          border: 3px solid var(--primary-theme);
        }

        svg {
          width: 24px;
          height: auto;
        }
      }
    }
  }

  .ActionRequests {
    position: absolute;
    top: 0px;
    margin-top: -40px;

    > div {
      width: fit-content;
      margin: auto;
      background: #ffffffec;
      border-radius: 50px;
      padding: 2px 10px;
      border: 1px solid var(--border-light);
      backdrop-filter: blur(20px);
    }
  }

  .ActionSettingsGenerate {
    flex: 0 1 24%;

    @include media('lg', 'max') {
      flex: 1 1 100%;
      width: 100%;
    }

    .ActionSettingsMap {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      align-items: center;

      svg {
        width: 14px;
        height: auto;
      }
    }

    .ActionSettingsMore {
      padding: 4px;
      border-radius: 8px;
      border: 1px solid var(--border-light);
      background: var(--primary-gray);

      svg {
        width: 16px;
        height: auto;
      }
    }
  }
}

.ButtonPills {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px 0;

  .ButtonPill {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    font-size: var(--font-p);
    border: 1px solid rgba(0, 0, 0, 0.08);

    &.active {
      background: var(--primary-theme);
      color: var(--text-black);
    }
  }
}

.ActionBlockSettingsPopover {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 18px;

  .ActionBlockSettingsPopover__icon {
    width: 18px;
    height: auto;

    path {
      fill: var(--text-black);
    }
  }

  .ActionBlockSettingsPopover__controls {
    > button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      font-size: var(--font-p);
      font-weight: 700;
      gap: 6px;
      padding: 8px 4px;
    }
  }
}

.ActionBlockSettingsPopover__image {
  position: relative;
  overflow: hidden;
  border-radius: 8px;

  .ActionBlockSettingsPopover__image__overlay {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 8px 12px;
    background: rgb(0 0 0 / 30%);
    height: 100%;

    > span {
      background: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(5px);
      font-size: var(--font-p);
      font-weight: 700;
      border-radius: 8px;
      padding: 4px 6px;
    }

    > button {
      svg {
        width: 18px;
      }
    }
  }
}

.ActionBlocksSettings__empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  z-index: 10;
}
