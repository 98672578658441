@use '@nex/labs/src/styles/common.scss' as *;

.BaseImagePaneSectionContentItem {
  flex: 0 0 46.3%;
  gap: 8px;
  position: relative;
  text-align: left;
  &[data-remove^='true'] {
    img {
      transition: all 0.1s ease-in-out;
      border: 2px solid var(--primary-theme);
      padding: 1px;
    }
  }

  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    &[data-replace^='true'] {
      &:before {
        content: 'Replace';
      }
    }

    &[data-remove^='true'] {
      &:before {
        content: 'Remove';
        background: var(--primary-red);
      }
    }

    &:before {
      background: var(--primary-theme);
      width: fit-content;
      content: 'Add Block';
      white-space: nowrap;
      transition: all 0.2s ease-in-out;
      padding: 8px 12px;
      color: var(--primary-dark);
      left: 50%;
      font-size: 14px;
      display: flex;
      font-size: var(--font-accent);
      border-radius: 6px;
      font-weight: 600;
      top: 50%;
      letter-spacing: -0.2px;
      justify-content: center;
      animation: scaleIn 0.3s ease-in-out;
      display: flex;
      transform: translate(-50%, -50%);
      position: absolute;
      z-index: 1;
      cursor: pointer;
    }

    @keyframes scaleIn {
      from {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.9);
      }
      to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
}

.ButtonPills {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px 0;

  .ButtonPill {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    font-size: var(--font-p);
    border: 1px solid rgba(0, 0, 0, 0.08);

    &.active {
      background: var(--primary-theme);
      color: var(--text-black);
    }
  }
}

.AssetsButton {
  display: flex;
  flex: 0 0 46.3%;
  max-width: 110px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &[data-selected^='true'] {
    &:before {
      content: 'Loading...';
      position: absolute;
      text-shadow: 1px 1px #0000007a;
      color: var(--primary-white);
      font-weight: 600;
      font-family: 'Inter';
      font-size: 12px;
      background: #00000091;
      padding: 2px 8px;
      border-radius: 6px;
      pointer-events: none;
    }
  }

  &[disabled] {
    opacity: 0.9;
    pointer-events: none;
    cursor: not-allowed;
  }

  img {
    border: 1px solid #0000001a;
  }

  &.active {
    img {
      border: 2px solid var(--primary-theme);
      padding: 1px;
    }
  }

  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    opacity: 0.7;
    .AssetsButtonSelect {
      visibility: visible;
      opacity: 1;
      border: 1px solid var(--border-light);
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      animation: scaleIn 0.3s ease-in-out;
      display: flex;
      background: white !important;
      border-radius: 7px;
      padding: 4px 8px;
      min-width: 95px;
      font-size: 14px;
      font-weight: 600;
    }

    @keyframes scaleIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  &Select {
    width: 90%;
    position: absolute;
    bottom: 6px;
    left: 50%;
    visibility: hidden;
    opacity: 0;
    transform: translateX(-50%);
    > fieldset select {
      background: url("data:image/svg+xml, %3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M2.12001 1.29007L6.00001 5.17007L9.88001 1.29007C9.97259 1.19749 10.0825 1.12405 10.2035 1.07394C10.3244 1.02384 10.4541 0.998047 10.585 0.998047C10.7159 0.998047 10.8456 1.02384 10.9665 1.07394C11.0875 1.12405 11.1974 1.19749 11.29 1.29007C11.3826 1.38265 11.456 1.49256 11.5061 1.61352C11.5562 1.73449 11.582 1.86414 11.582 1.99507C11.582 2.126 11.5562 2.25565 11.5061 2.37661C11.456 2.49757 11.3826 2.60749 11.29 2.70007L6.70001 7.29007C6.60749 7.38277 6.4976 7.45632 6.37663 7.5065C6.25566 7.55668 6.12597 7.58251 5.99501 7.58251C5.86404 7.58251 5.73435 7.55668 5.61338 7.5065C5.49241 7.45632 5.38252 7.38277 5.29001 7.29007L0.700006 2.70007C0.607302 2.60755 0.533755 2.49767 0.483573 2.37669C0.433392 2.25572 0.407562 2.12604 0.407562 1.99507C0.407562 1.8641 0.433392 1.73442 0.483573 1.61344C0.533755 1.49247 0.607302 1.38258 0.700006 1.29007C1.09001 0.910068 1.73001 0.900068 2.12001 1.29007Z' fill='%23666666' /%3E%3C/svg%3E")
        no-repeat right 6px center/12px 10px rgba(255, 255, 255, 0.9) !important;
      padding: 5px 23px 5px 8px !important;
    }
  }

  select {
    font-size: var(--font-accent);
  }

  .AssetButtonImg {
    width: 100%;
    height: 105px;
    object-fit: cover;
    border-radius: 8px;
  }
}

.AssetUpload {
  display: flex;
  flex-direction: column;
  justify-content: center;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  background: #f9fafb;
  border: 1px solid var(--border-light);
  border-radius: 8px;
  padding: 14px 0px 0px;
  overflow: hidden;

  button {
    border-radius: 0px 0px 0px 0px !important;
    background: var(--primary-white) !important;
    margin-top: 10px;
    border: none !important;
    border-top: 1px solid var(--border-light) !important;
  }
}

.PanelSelection {
  background: #00000005;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid var(--border-light);
}

.PreviewAccordionHeader {
  display: flex;
}

.AspectRatioElement {
  height: calc(var(--height) / 50);
  width: calc(var(--width) / 50);

  border: 1px solid #d0d5dd;
  border-radius: 2px;
}

.ButtonAspect {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  width: 100%;
}

.ButtonAspectWrap {
  background: var(--primary-white);
  border: 1px solid var(--border-light);

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.FieldArtboardActionInput {
  input {
    font-size: 14px;
    background: var(--primary-gray);
    padding: 8px;
    width: 100%;
    border-radius: 6px;
  }
}

.ModelButton {
  flex: 0 0 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 100%;
  max-height: 100px;
  max-width: 100px;
  padding: 8px;
  border-radius: 10px;
  background: linear-gradient(90deg, #fbed96 0%, #abecd6 100%);
}

.ModelsUpsell {
  background: var(--primary-gray);
  padding: 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 1rem;
}

.ModelInfo {
  overflow: hidden;
  position: relative;
  border-radius: 10px;

  img {
    max-height: 250px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .ModelInfoContent {
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: flex-end;
    padding: 12px 16px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 49.5%
    );
  }
}

.PresetButton {
  flex: 0 0 46.3%;
  gap: 8px;
  position: relative;
  text-align: left;
  box-sizing: border-box;
  max-height: 80px;
  isolation: isolate;
  border-radius: 12px;
  overflow: hidden;
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 47.5%,
      rgba(0, 0, 0, 0.8) 85.5%
    );
  }

  > p {
    position: absolute;
    bottom: 0;
    left: 50%;
    line-height: 1.4 !important;
    color: #fff;
    font-weight: 700;
    transform: translateX(-50%);
    padding-bottom: 4px;
  }
}
