@use '@nex/labs/src/styles/common.scss' as *;

.ConsoleLayout {
  overflow: hidden;
  display: flex;
  position: relative;
  --nav-height: 77px;

  @include media('lg', 'max') {
    padding: 0;
    --nav-height: 100px;
  }

  @include media('lg', 'max') {
    &Main {
      max-width: 100% !important;
      margin: auto;
    }
  }

  .ConsoleLayoutAsideMeta {
    padding: 6px;
    background: bg('black', '6');
    border: 1px solid bg('black', '1');
    border-radius: 8px;
  }

  &Aside {
    .ConsoleLayoutAsideNavRightIcon {
      margin-left: auto;
    }

    @include media('xxl', 'max') {
      &:not(.openNav) {
        .ConsoleLayoutAsideNavRightIcon {
          display: none;
        }
      }
    }
  }

  @include media('xxl', 'max') {
    &Aside {
      &.openNav {
        z-index: 10000;
        height: var(--nex-labs-vh) !important;
        top: 0 !important;
        left: 0 !important;
        transform: translateX(0) !important;
        max-width: 300px !important;
        background: var(--primary-white);
        padding: 14px;
      }

      &:not(.openNav) {
        max-width: var(--sidebar-width) !important;
        align-items: center;
        padding: 14px 0px;

        .ConsoleLayoutAsideMetaWrap {
          align-items: center;
          height: 100%;
        }

        h6,
        p,
        .ConsoleLayoutAsideMetaName,
        .PlanReading,
        .Footer,
        a > span {
          display: none;
        }

        a {
          justify-content: center;

          svg {
            width: 20px;
          }
        }

        .ConsoleLayoutAsideLogo {
          justify-content: center !important;
          svg {
            width: 16px;
          }

          span {
            display: none;
          }
        }
      }
    }
  }

  @include media('lg', 'max') {
    &Aside {
      transform: translateX(-1000%) !important;
    }

    &Main {
      max-width: 100% !important;
      margin: auto;
    }
  }

  @include media('xxl', 'max') {
    &AsideOverlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: var(--nex-labs-vh);
      background: rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(6px);
      z-index: 9999;
      display: none;

      &.openNav {
        display: block;
      }
    }
  }

  &Aside {
    // background: var(--primary-dark);
    // border: 1px solid bg('white', 'faint');
    @include custom-scrollbar();
    overflow-x: auto;
    width: 100%;
    left: 15px;
    top: 15px;
    margin-top: var(--nav-height);
    padding: 8px 0;
    position: fixed;
    word-break: break-all;
    // border-radius: 12px;
    max-width: var(--sidebar-width);
    height: calc(var(--nex-labs-vh) - var(--nav-height) - 32px);
    display: flex;
    flex-direction: column;
    gap: 32px;

    @include media('xl', 'max') {
      padding: 14px;
    }

    :global(.am-labs-spacer) {
      flex: none !important;
    }

    .ConsoleLayoutAsideLogo {
      display: flex;
      gap: 6px;

      span {
        font-size: var(--font-caption);
        text-transform: uppercase;
        font-weight: 700;
      }

      svg {
        width: 12px;
        height: auto;
      }
    }

    .ConsoleLayoutAsideNav {
      a {
        display: flex;
        gap: 14px;
        align-items: center;
        padding: 10px 8px;
        border-radius: 8px;
        border: 1px solid transparent;
        color: var(--text-black);

        &:hover {
          background: bg('black', '3');
          transition: all 0.5s ease;
        }

        span {
          font-weight: 600;
        }

        svg {
          width: 20px;
          height: auto;
        }

        svg:not(.isStroke) {
          path {
            fill: var(--text-black);
          }
        }

        svg.isStroke {
          path {
            stroke: var(--text-black);
          }
        }

        &.active {
          background: bg('black', '6');
        }
      }
    }
  }

  &Main {
    margin-left: auto !important;
    width: 100%;
    right: 0;
    overflow: hidden;
    position: relative;
    margin-top: var(--nav-height);
    background: var(--primary-white);

    max-width: calc(100% - (var(--sidebar-width) + 32px));

    @include media('lg', 'max') {
      padding: 8px 14px;
    }

    .ConsoleLayoutMainContent {
      border-radius: 0px 0px 0px;
      padding: 18px 32px 32px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      min-height: var(--nex-labs-vh);

      @include media('lg', 'max') {
        padding: 20px 8px 32px;
        box-shadow: none;
        background: var(--primary-white);
        border-radius: 10px;
      }
    }

    .MainContent {
      // max-width: var(--max-width);
      margin: 0 auto;
      width: 100%;
    }
  }
}

.ConsoleLayoutMainNav {
  gap: 12px;
  position: relative;
  z-index: 101;
  padding: 8px 32px;

  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: #ffffff;

  @include media('lg', 'max') {
    max-width: 100%;
    padding: 8px 18px 8px;
  }

  fieldset {
    max-width: 300px;
  }

  fieldset > div {
    margin: 0;
    backdrop-filter: blur(0px);

    > div {
      margin: 12px 0px 12px 12px !important;
      width: 14px !important;
      height: 14px !important;
    }

    input {
      padding: 10px;
      font-weight: 600;
    }
  }
}

.PlanReading {
  background: var(--primary-white);
  border-radius: 12px;
  border: 1px solid bg('black', '8');
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  word-break: break-word;

  &Asset {
    background: var(--primary-blue);
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 50%;
    justify-content: center;
  }

  progress {
    display: block;
    position: relative;
    appearance: none;
    width: 100%;
    height: 6px;
    border: 0;
    margin-top: 10px;

    &::-webkit-progress-bar {
      background-color: bg('black', 'soft');
      border-radius: 10px;
      overflow: hidden;
    }

    &::-webkit-progress-value {
      width: var(--webkitProgressPercent) !important;
      background-image: var(--gradient);
      border-radius: 10px;
    }

    &::-moz-progress-bar {
      width: var(--webkitProgressPercent) !important;
      background-image: var(--gradient);
      border-radius: 10px;
    }
  }
}
