.Layout {
  display: flex;
  flex-direction: column;
  min-height: var(--nex-labs-vh);

  .LayoutFooter {
    flex-shrink: 0;
  }

  .LayoutHeader {
    flex-shrink: 0;
  }

  .LayoutMain {
    margin-top: var(--nav-height);
    flex: 1;
  }
}
