@use '@nex/labs/src/styles/common.scss' as *;

.SketchWrapper {
  margin: 70px auto;
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  height: calc(100vh - 200px);
  padding: 0 18px;
  justify-content: center;
}

.Wrapper {
  // background: #e5e5e5;
  border-radius: 8px;
  // border: 1px solid #d9d9d9;
  > main {
    max-height: calc(100vh - 200px) !important;

    > section {
      max-width: 100% !important;
      max-height: calc(100vh - 200px) !important;

      * {
        max-width: 100% !important;
        max-height: calc(100vh - 200px) !important;
      }
    }
  }
}

.Saving {
  position: fixed;
  top: 64px;
  left: var(--floating-controls);
  width: -moz-fit-content;
  width: fit-content;
  gap: 14px;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 50px;
  background: var(--primary-gray);
  z-index: 100;
  border: 2px solid var(--primary-white);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  animation: slideIn 0.3s ease-in-out both;

  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(-40%) translateX(-50%);
    }
    100% {
      opacity: 1;
      transform: translateY(0) translateX(-50%);
    }
  }
}

.InputWrap {
  position: fixed;
  bottom: 24px;
  left: var(--floating-controls);

  width: 440px;

  .TextareaWrap {
    width: 100%;
    border: 6px solid var(--primary-white);
    border-radius: 12px;
    background-color: var(--primary-gray);
    z-index: 1;
    padding: 14px 14px 24px;

    button {
      background: var(--primary-theme);
      font-size: 14px;
      padding: 5px 10px;
      color: #000;
      border-radius: 8px;
    }
  }

  textarea {
    width: 100%;
    background-color: var(--primary-gray);
    min-height: 30px;
    resize: none;
    outline: none;
  }

  button {
    background: #303030;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 6px;
  }
}

.Controls {
  background: var(--primary-gray);
  padding: 4px;

  display: flex;
  align-items: center;
  gap: 8px;

  background: var(--primary-white);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  span {
    background: #e9ecf2;
    width: 1px;
    height: 30px;
    display: block;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 8px;
    border-radius: 6px;
    font-weight: 600;
    font-size: var(--font-p);
    background: var(--primary-gray);

    svg {
      height: 20px;
      width: 20px;
    }
  }
}

.WrongCanvasSize {
  // position: fixed;
  // bottom: 8px;
  // left: var(--floating-controls);
  width: -moz-fit-content;
  width: fit-content;
  max-width: 300px;
  gap: 14px;
  display: flex;
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  border-radius: 0;
  background: var(--primary-gray);
  z-index: 100;
  border: 3px solid var(--primary-white);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  animation: slideIn 0.3s ease-in-out both;
  margin: 0 -14px;

  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(8px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
