$current-theme: 'light' !default;
$default-font-family:
  -apple-system,
  BlinkMacSystemFont,
  Segoe UI,
  Roboto,
  Oxygen,
  Ubuntu,
  Cantarell,
  Fira Sans,
  Droid Sans,
  Helvetica Neue,
  sans-serif;
$default-browser-font-size: 16px;
$base-font-size: 16px;

$stacking-order: (
  playing-area: 1001,
  popper: 1400,
  skip-link: 1005,
  navbar: 1001,
  modal: 1400,
  navbar-dropdown: 1002,
);

$breakpoints: (
  'xs': 0,
  'sm': 576px,
  'md': 768px,
  'lg': 1024px,
  'xl': 1280px,
  'xxl': 1400px,
);

$spacing: (
  'none': 0,
  'xs': 0.25rem,
  'sm': 0.5rem,
  'md': 1rem,
  'lg': 1.5rem,
  'xl': 2rem,
  'xxl': 3rem,
);

/// Returns the z-index of the specified element.
/// @param {String} $element - The key for the element.
/// @return {Number} The z-index for the element.

@function z-index($element) {
  $index: map-get($stacking-order, $element);

  @if $index {
    @return $index;
  } @else {
    @error 'z-index `#{$element}` in `#{$context}` not found.';
  }
}

/// Returns the value in rem for a given pixel value.
/// @param {Number} $value - The pixel value to be converted.
/// @return {Number} The converted value in rem.

@function rem($value) {
  $unit: unit($value);

  @if $value == 0 {
    @return 0;
  } @else if $unit == 'rem' {
    @return $value;
  } @else if $unit == 'px' {
    @return calc($value / $base-font-size) * 1rem;
  } @else if $unit == 'em' {
    @return $unit / 1em * 1rem;
  } @else {
    @error 'Value must be in px, em, or rem.';
  }
}

@function bareRem($value) {
  @return #{stripUnit($value) / stripUnit($base-font-size) * 1rem}rem;
}

@function spacing($type: 'none') {
  $property: map-get($spacing, $type);

  @if $property {
    @return $property;
  } @else {
    @error 'Spacing must be one of none, xs, sm, md, lg, xl, or xxl.';
  }
}

/// media query mixin
/// @param {String} $breakpoint - The breakpoint to be used.
/// @param {String} $type - The type of media query to be used.
/// @return {String} The media query.
/// @example

@mixin media($breakpoint: 'md', $type: 'min') {
  $value: map-get($breakpoints, $breakpoint) or $breakpoint;

  @if $value {
    @if $type == 'min' {
      @media (min-width: #{$value}) {
        @content;
      }
    } @else if $type == 'max' {
      @media (max-width: calc(#{$value} - 0.02px)) {
        @content;
      }
    } @else {
      @error 'Type must be either min or max.';
    }
  } @else {
    @error 'Breakpoint must be one of xs, sm, md, lg, xl, or xxl.';
  }
}

$typography: (
  'h1': 'h1',
  'h2': 'h2',
  'h3': 'h3',
  'h4': 'h4',
  'h5': 'h5',
  'h6': 'h6',
  'p': 'p',
  'button': 'h6',
  'a': 'p',
  'span': 'p',
  'regular': 'p',
  'small': 'small',
  'accent': 'accent',
  'caption': 'caption',
);

@mixin typography($type: 'regular') {
  $property: map-get($typography, $type);

  @if $property {
    @include font-size($property);

    @if $property == 'h1' {
      font-weight: var(--font-weight-bold);
      letter-spacing: var(--letter-spacing-wider);
      line-height: 54px;
    } @else if $property == 'h2' {
      font-weight: var(--font-weight-bold);
      letter-spacing: var(--letter-spacing-wide);
      line-height: 45px;
    } @else if $property == 'h3' {
      font-weight: var(--font-weight-medium);
      letter-spacing: var(--letter-spacing-regular);
      line-height: 45px;
    } @else if $property == 'h4' {
      font-weight: var(--font-weight-medium);
      letter-spacing: var(--letter-spacing-regular);
      line-height: 33px;
    } @else if $property == 'h5' {
      font-weight: var(--font-weight-medium);
      letter-spacing: var(--letter-spacing-regular);
      line-height: 26px;
    } @else if $type == 'button' {
      font-weight: var(--font-weight-medium);
      letter-spacing: calc(var(--letter-spacing-regular));
      line-height: 130%;
    } @else {
      font-weight: var(--font-weight-regular);
      letter-spacing: var(--letter-spacing-regular);
      line-height: 1.65;
    }
  } @else {
    @error 'Type must be one of h1, h2, h3, h4, h5, p, button, a, span, or regular.';
  }
}

@mixin font-size($size: 1rem) {
  $property: map-get($typography, $size);

  @if $property {
    font-size: var(--font-#{$property});
  } @else {
    font-size: rem($size);
  }
}

$color-map: (
  'black': (
    16,
    16,
    16,
  ),
  'green': (
    48,
    217,
    94,
  ),
  'gray': (
    31,
    31,
    31,
  ),
  'red': (
    239,
    80,
    80,
  ),
  'blue': (
    46,
    60,
    187,
  ),
  'white': (
    255,
    255,
    255,
  ),
);

$intensity-map: (
  'faint': 3,
  'soft': 5,
  'light': 30,
  'regular': 55,
  'strong': 75,
  'heavy': 90,
);

@function theme-color($color-name) {
  @if $current-theme == 'light' {
    @return map-get($color-map-light, $color-name);
  } @else {
    @return map-get($color-map-dark, $color-name);
  }
}

@function is-hex($str) {
  @return str-slice($str, 1, 1) == '#' and
    (str-length($str) == 7 or str-length($str) == 4);
}

@function hex-to-rgb($hex) {
  $color: unquote($hex); // Convert the hex string to a color
  @if str-length($hex) == 4 {
    $r: red($color * 2);
    $g: green($color * 2);
    $b: blue($color * 2);
  } @else {
    $r: red($color);
    $g: green($color);
    $b: blue($color);
  }
  @return $r, $g, $b;
}

@function color-to-rgb($color) {
  @return red($color), green($color), blue($color);
}

@function get-color($color-name) {
  @if map-has-key($color-map, $color-name) {
    @return map-get($color-map, $color-name);
  } @else if is-hex($color-name) {
    @return hex-to-rgb($color-name);
  } @else {
    @return color-to-rgb($color-name);
  }
}

@function get-intensity($intensity-key) {
  @if map-has-key($intensity-map, $intensity-key) {
    @return map-get($intensity-map, $intensity-key);
  } @else {
    @return #{$intensity-key};
  }
}

@function bg($color-name, $intensity-key) {
  $color: get-color($color-name);
  $r: nth($color, 1);
  $g: nth($color, 2);
  $b: nth($color, 3);
  $alpha: calc(get-intensity($intensity-key) / 100);
  @return rgba($r, $g, $b, $alpha);
}

@mixin applySmartBg($styles, $intensity, $blur, $dots: false) {
  background: bg($styles, $intensity);

  @if $blur {
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      backdrop-filter: blur(var(--blur-#{$blur}));
      -webkit-backdrop-filter: blur(var(--blur-#{$blur}));
    }
  }

  @if $dots {
    background-image: radial-gradient(bg('white', 'soft') 1px, transparent 1px);
    background-size: calc(10 * 1px) calc(10 * 1px);
  }
}

@mixin custom-scrollbar {
  scrollbar-width: 0;
  -ms-overflow-style: none;
  transition: all 0.2s ease;

  &::-webkit-scrollbar {
    width: 8px;
    height: 6px;
  }

  @media (max-width: 768px) {
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: rgb(255 255 255 / 15%);
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
    border: 3px solid transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--gray-lighter);
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: var(--gray-lightest);
  }

  &::-webkit-scrollbar-corner,
  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-resizer,
  &::-webkit-scrollbar-gripper,
  &::-webkit-scrollbar-gripper:hover,
  &::-webkit-scrollbar-gripper:active,
  &::-webkit-scrollbar-track-piece {
    display: none;
  }
}

@mixin gradientText {
  background: linear-gradient(159deg, #b1b1b1 -4.81%, #000000 99%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 900;
  line-height: 1.3;
}
