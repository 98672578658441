@use '@nex/labs/src/styles/common.scss' as *;

.ArtboardLayout {
  background: var(--primary-gray);
  --aside-width: 325px;
  --floating-controls: calc(
    50% - var(--preview-width) / 2 + var(--aside-width) / 2
  );
  --main-width: calc(
    100% - calc(
        var(--aside-width) +
          var(
            --preview-width,
            calc(var(--aside-width) - calc(var(--aside-width) / 5))
          )
      )
  );

  @include media('xl', 'max') {
    --aside-width: 280px !important;
    --preview-width: 0px !important;
  }

  @include media('lg', 'max') {
    --aside-width: 0px !important;
    --floating-controls: calc(50% + var(--aside-width) / 2);
    --preview-width: 0px !important;
  }

  &.isRealtime {
    --aside-width: 75px;
  }

  .ArtboardMain {
    margin-left: var(--aside-width);
    width: var(--main-width);
    position: relative;
    min-height: var(--nex-labs-vh);
    height: auto;
    background-color: var(--primary-gray);
    // background-image: radial-gradient(#d9d9d9 1.5px, transparent 1.5px);
    background-size: calc(14 * 1.5px) calc(14 * 1.5px);
    display: flex;
    flex-direction: column;

    .ArtboardMainContent {
      flex: 1;
    }
  }
}
