@use '@nex/labs/src/styles/common.scss' as *;

.EmptyStateBlocks {
  &.full {
    background: var(--primary-white);
    border: 1px solid var(--border-light);
    box-shadow: 0px 2px 1px rgba(16, 24, 40, 0.05);
    border-radius: 12px;
    position: absolute;
    top: 50%;
    height: 100%;
    max-height: 600px;
    width: 100%;
    max-width: 640px;
    overflow: hidden;

    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;

    h4 {
      position: absolute;
      color: #fff;
      font-weight: 700;
      margin-top: 24px;
      text-align: center;
    }

    .EmptyStateBlocksContent {
      display: flex;
      gap: 32px;
      position: relative;

      &Connector {
        position: absolute;
        left: 50%;
        transform: translatex(-50%);
        top: -40px;
        width: 100%;
      }

      &Tile {
        max-width: 160px;
        background: #f2f2f285;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        height: 100%;
        border: 1px solid var(--border-light);
        padding: 9px;

        img {
          height: 90px;
          width: 100%;
          object-fit: cover;
          border-radius: 8px;
        }
        svg {
          height: 18px;
          width: auto;
        }

        @include media('lg', 'max') {
          display: none;
        }
      }
    }

    @include media('lg', 'max') {
      width: 100%;
      height: 100%;
      max-width: 300px;
      max-height: 250px;
    }
  }
}

.BlockPaneHistory {
  flex-wrap: wrap;
  display: grid;
  gap: 16px;
  padding: 14px;

  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  position: relative;

  img.GenerationImg {
    height: 200px;
    object-fit: cover;
    border-radius: 6px;
    width: 100%;
  }
}

.ContentLoader {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: #f0f0f0;
  position: relative;
  overflow: hidden;
  border: 1px solid var(--primary-white);
}

.ContentLoader::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    #dedede 50%,
    transparent 100%
  );
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.BlockPaneGenerations {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 14px 14px 15rem;

  .BlockPaneGeneration {
    display: flex;
    gap: 24px;
    flex-direction: column;

    .sparkles {
      margin-right: -6px;
      color: var(--primary-theme);
      width: 16px;
      height: 16px;
      margin-top: -6px;
      margin-left: -6px;
    }
  }

  .BlockPaneGenerationImages {
    flex-wrap: wrap;
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    position: relative;

    @include media('sm', 'max') {
      grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    }

    img.GenerationImg,
    .BlockPaneGenerationImageLoading {
      width: 100%;
      height: auto;
      border-radius: 6px;
      min-height: 200px;
      // max-height: 220px;
      object-fit: cover;
      background: var(--primary-white);

      @include media('md', 'max') {
        max-height: 200px;
      }
    }

    .BlockPaneGenerationImageActions {
      position: relative;
      height: 100%;

      @include media('md', 'max') {
        height: auto;
      }

      &:hover,
      &:focus-visible,
      &:focus-within {
        .BlockPaneGenerationImageAction {
          visibility: visible;
          display: flex;
          opacity: 1;
          transition:
            visibility 0s,
            opacity 0.2s ease;
        }
      }

      .BlockPaneGenerationImageAction {
        visibility: hidden;
        opacity: 0;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        right: 14px;
        top: 8px;

        .button {
          background: bg('black', 'heavy');
          backdrop-filter: blur(4px);
          border: 1px solid bg('black', '6');
          border-radius: 40px;
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background: bg('black', '50');
            transition: background 0.2s ease;
          }
        }

        svg {
          width: auto;
          height: 18px;

          path {
            fill: var(--primary-white);
          }
        }
      }
    }
  }
}

.BlockUser {
  align-items: center;
  gap: 4px;
  position: absolute;
  z-index: 1;
  /* left: 12px; */
  margin-left: 8px;
  margin-top: 8px;
  background: #fbfbfb;
  border: 1px solid var(--border-light);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 2px 2px;
  transition: all 0.2s ease;

  border-radius: 50px;

  &:hover {
    padding: 4px;
    > p {
      display: block;
    }
  }

  > figure {
    flex: 0 0 20px;
    border-radius: 50%;
  }

  > p {
    flex: 1;
    display: none;
  }
}
