@use '@nex/labs/src/styles/common.scss' as *;

.AnimatorSidebar {
  width: var(--aside-width);
  height: var(--nex-labs-vh);
  background: var(--primary-white);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 16px;
  box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.05);
  gap: 24px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @include media('md', 'max') {
    flex-wrap: wrap;
  }
}
