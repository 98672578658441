@use '@nex/labs/src/styles/common.scss' as *;

.GetHelpWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.GetHelpIntroSlateBG {
}

.GetHelpIntroSlate {
  position: relative;
  overflow: hidden;
  padding: 0px 8px 32px;
  background-size: 100%;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h4 {
    position: relative;
  }
}
