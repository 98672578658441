@use '@nex/labs/src/styles/common.scss' as *;

.Accordion {
  background: var(--primary-gray);
  padding: 12px;
  border: 1px solid bg('black', '5');
  width: 100%;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: fit-content;

  &.bare {
    padding: 0;
    border: none;
    background: transparent;
  }

  &.light {
    background: bg('black', 'faint');
  }

  .AccordionHeader {
    display: flex;
    background: transparent;
    box-shadow: none;
    width: 100%;
    border: none;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    img {
      transition: all 0.2s ease-in-out;
    }

    .arrow {
      width: 16px;
      height: 16px;
    }

    &.isOpen {
      color: var(--primary-theme);
      .arrow {
        transform: rotate(180deg);
      }
    }

    p {
      text-align: left;
    }

    &.lg {
      p {
        font-size: 18px;
      }
    }

    &.md {
      p {
        font-size: 16px;
      }
    }

    &.sm {
      > span {
        font-size: 14px !important;
      }

      .arrow {
        width: 12px;
        height: 12px;
      }
    }
  }

  .AccordionBody {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
}
