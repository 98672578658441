@use '@nex/labs/src/styles/common.scss' as *;

.GenerativeLoading {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  margin-top: 18px;

  .avatarGradient {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: relative;
    top: 5px;
    margin-right: 5px;
    display: inline-block;
    background: var(--gradient);
  }

  progress {
    display: block;
    position: relative;
    appearance: none;
    width: 100%;
    max-width: 500px;
    height: 6px;
    transition: all 0.2s ease;
    border: 0;
    margin: 10px 0 2px;
    background: var(--primary-white);

    &::-webkit-progress-bar {
      background-color: bg('white', 'soft');
      border-radius: 10px;
      overflow: hidden;
      transition: all 0.2s ease;
    }

    &::-webkit-progress-value {
      width: var(--webkitProgressPercent) !important;
      background-image: var(--gradient);
      transition: all 0.2s ease;
      border-radius: 10px;
    }

    &::-moz-progress-bar {
      width: var(--webkitProgressPercent) !important;
      background-image: var(--gradient);
      transition: all 0.2s ease;
      border-radius: 10px;
    }
  }

  &Progress {
    background-color: var(--primary-white);
    border-radius: var(--border-radius-md);
    padding: 18px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    max-width: 500px;

    &:before {
      background-color: var(--primary-white);
      width: 90%;
      height: 10px;
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 0px 0px 10px 10px;
      z-index: -1;
    }
  }
}
