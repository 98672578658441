@use '@nex/labs/src/styles/common.scss' as *;

.ConsoleActionSlateButtons {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  grid-template-rows: 1fr;
  grid-column-gap: 18px;
  grid-row-gap: 18px;

  &.sm {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

    .ConsoleActionSlateButton {
      padding: 18px;
      gap: 18px;

      *:not(img) {
        position: relative;
        z-index: 1;
      }

      img {
        height: 100%;
        filter: blur(4px);
        border-radius: 0;
        margin-left: -32px;
        object-fit: cover;
        position: absolute;
        width: 100%;
        margin-top: -24px;
        opacity: 0.12;
        z-index: 0;
        max-width: 100%;
      }
    }
  }

  @include media('lg', 'max') {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .ConsoleActionSlateButton {
    display: flex;
    border-radius: 8px;
    padding: 0px;
    overflow: hidden;
    gap: 28px;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.7);
    background-blend-mode: overlay;
    justify-content: flex-start;
    padding: 24px;
    flex: 1;
    color: var(--primary-white);
    position: relative;
    background-size: 100%;
    background-position: center;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &.LightVersion {
      box-shadow: 0px 2px 4px 0px #0000000d;
      background: var(--primary-gray);
      color: var(--text-black);
      border: 1px solid var(--primary-gray);
    }

    h3 {
      letter-spacing: var(--letter-spacing-wide);
    }

    button {
      padding: 0;
      height: 40px;
      width: 40px;
      background: transparent;
      border: 2px solid #ffffff;

      > span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
      }

      .CallToActionText {
        display: none;
      }
    }

    &:hover,
    &:focus-visible {
      transition:
          // background-color 0.2s ease-in-out,
        background-size 0.6s ease-in-out;
      background-size: 120%;
      background-color: rgba(0, 0, 0, 0.3);

      button {
        height: 40px;
        width: fit-content;
        background: #fff;
        border: 2px solid transparent;
        padding: 10px 20px;
        span {
          filter: invert(1);
        }
        .CallToActionText {
          display: block;
        }
      }
    }

    @include media('lg', 'max') {
      min-width: 100%;
    }

    // img {
    //   height: 100%;
    //   max-width: 90px;
    //   border-radius: 0;
    //   margin-left: -32px;
    //   object-fit: contain;

    //   @include media('md', 'max') {
    //     display: none;
    //   }
    // }
  }
}
