@use '@nex/labs/src/styles/common.scss' as *;

.Nav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px 0;
  position: fixed;
  width: 100%;
  min-height: var(--nav-height);
  top: env(safe-area-inset-top, 0px);
  z-index: z-index(navbar);
  transition: all 0.3s ease-in-out;

  @include media('xl', 'max') {
    border: none;
    padding: 8px 0;
  }

  &.NavbarScrolled {
    position: fixed;
    top: 0;
    left: 0;

    right: 0;
    width: 100%;
    @include applySmartBg('black', '3', 'normal');
  }
}

.Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 15px;
  gap: 30px;
  padding-left: 15px;
  max-width: 1670px;
  margin: auto;

  a {
    @include typography('h6');
    font-weight: 600;
    letter-spacing: var(--letter-spacing-regular);
    color: #475569;

    &:hover {
      color: var(--primary-theme) !important;
    }
  }

  &LeftItems {
    svg {
      width: 80px;
      height: auto;

      &:first-child {
        display: block;
      }

      &:last-child {
        display: none;
      }

      @include media('md', 'max') {
        &:first-child {
          display: none;
        }

        &:last-child {
          display: block;
          height: 36px;
          width: auto;
        }
      }
    }
  }

  &CenterItems {
    margin-left: 110px;

    button {
      @include typography('h6');
      font-weight: 600;
      letter-spacing: var(--letter-spacing-regular);
      color: #475569;

      &:hover {
        color: var(--primary-theme) !important;
      }
    }

    @include media('lg', 'max') {
      margin-left: 0px;
      display: none;
    }
  }

  &RightItems {
    display: flex;
    align-items: center;
    gap: 30px;
  }
}
