@use '@nex/labs/src/styles/common.scss' as *;

.ConsoleLayoutHeaderNav {
  --gap: 24px;

  display: flex;
  gap: var(--gap);
  align-items: center;
  justify-content: flex-end;
  padding: 12px 0;

  @include media('lg', 'max') {
    height: fit-content;
    flex-wrap: wrap;
    padding: 8px 0;
    justify-content: flex-start;
    margin-bottom: 14px;
  }

  &Logo {
    margin-right: auto;

    @include media('lg', 'max') {
      justify-content: space-between;
      align-items: center;
      display: flex;
      width: 100%;
    }
  }

  &UserName {
    @include media('lg', 'max') {
      display: none;
    }
  }

  &Menu {
    gap: var(--gap);

    &Item {
      display: flex;
      align-items: center;
      gap: 8px;

      &Label {
        @include media('lg', 'max') {
          display: none;
        }
      }

      svg {
        width: 20px;
      }
    }
  }
}
