@use '@nex/labs/src/styles/common.scss' as *;

.Collaborators {
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 14px;

  input,
  select {
    font-size: 14px;
    background: bg('black', '6');
    padding: 8px;
    border-radius: 6px;
  }
}

.collaborators {
  background: bg('black', '7');
  border-radius: 8px;
  padding: 2px 4px;
  border: 1.5px solid var(--primary-theme);
  display: flex;
  gap: 4px;

  svg {
    height: auto;
    width: 18px;

    path {
      // stroke-width: 2px;
      stroke: #000;
    }
  }
}

.FloatingNav {
  background: var(--primary-gray);
  padding: 4px;
  left: var(--floating-controls);
  transform: translateX(-50%);
  position: fixed;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 100;
  background: var(--primary-white);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  &.onlyShow {
    display: flex;
  }

  &.hide {
    display: none;
  }

  &.NavToolBar {
    top: 18px;

    @include media('lg', 'max') {
      top: unset;
      bottom: 15rem;
      height: fit-content;
    }

    button {
      background: var(--primary-gray);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      padding: 8px;
      border-radius: 6px;
      font-weight: 600;
      font-size: var(--font-p);

      &:hover {
        background: #e9ecf2;
      }

      &:disabled {
        opacity: 0.8;
        cursor: not-allowed;
      }
    }
  }

  &.ActionBar {
    bottom: 14px;
    max-width: calc(1540px - 10%);
    width: calc(84% - var(--sidebar-width));
    transform: translateX(-50%);
    background: var(--primary-white);
    border: 1px solid var(--border-light);

    @include media('xxl', 'max') {
      max-width: 70% !important;
      width: 70% !important;
    }

    @include media('lg', 'max') {
      max-width: 98% !important;
      width: 98% !important;
    }

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      backdrop-filter: blur(20px) saturate(184%);
      -webkit-backdrop-filter: blur(20px) saturate(184%);
      background: rgba(255, 255, 255, 0.8);
    }
  }
}
