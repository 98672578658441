@use '@nex/labs/src/styles/common.scss' as *;

.HeaderNavMenu {
  --gap: 24px;
  gap: var(--gap);

  &Item {
    display: flex;
    align-items: center;
    gap: 8px;

    &Label {
      @include media('lg', 'max') {
        display: none;
      }
    }

    svg {
      width: 20px;
    }
  }
}
