.AnimateForm {
  &Image {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    background: #f9fafb;
    border: 1px solid var(--border-light);
    border-radius: 8px;

    &Input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 14px 0px 0px;
      overflow: hidden;
      border-radius: 8px;


      button {
        border-radius: 0px 0px 0px 0px !important;
        background: var(--primary-white) !important;
        margin-top: 10px;
        border: none !important;
        border-top: 1px solid var(--border-light) !important;
      }
    }
  }
}


.ImagePreview {
  position: relative;

  &, img {
    border-radius: 8px;
  }

  &ResetCTA {
    position: absolute;
    bottom: 8px;
    right: 8px;
    width: 20px;
    height: 20px;
    
    svg {
      width: 12px;
    }
  }
}